import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import PPPIcon from "../../../../assets/media/icons/other_icons/PPPIcon.svg";
import handCross from "../../../../assets/media/icons/other_icons/cross-hand-icon.svg";
import EditSquareSVG from "../../../../assets/media/icons/other_icons/edit-square.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import logIcon from "../../../../assets/media/icons/other_icons/logSvg.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

import clsx from "clsx";
import NoJSXComponent from "components/noJSX/NoJSXComponent";
import Button from "components/ui/buttons/Button";
import InventoryNoJSX from "pages/inventory/core/InventoryNoJSX";
import { Tooltip } from "react-tooltip";
import {
  getBenefitsValue,
  getCategoryValue,
  getRestrictionValue,
  getSectionValue,
  getTicketType,
} from "../../../../helpers/AssetHelpers";
import {
  colorForMeter,
  decimalNumber,
  handleShift,
  isDateBeforeValidLogDate,
  priceCalculation,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../../helpers/const";
import { TicketError } from "../../../add_inventory/core/_functions";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  calculateAvgPriceForProceedValue,
  setEventRecordPayload,
  visibilityMeterCalculationForInventory,
  visiblityMeterForBelowListing,
} from "../../core/_functions";
import { UpdateInventory } from "../../core/_request";
import TicketTypeExist from "../nonJSXComponent/TicketTypeExist";
import Image from "./mobileViewComponent/Image";
import RenderList from "./mobileViewComponent/RenderList";
import EventRecordStatus from "./viewableComponent/EventRecordStatus";

const EventFieldViewMobile = ({
  // eventRecord,
  setList,
  list,
  eIndex,
  rIndex,
  event,
  disabledAll,
  forRowDisabled,
  currentRecordId,
  setCurrentRecordId,
  isTableEditable,
  firstSelectedIndex,
  getColumnItems,
  Messages,
  subTicketType,
  tempEventRecord,
  updateVisibility,
  setIsOpenListingQualityPopup,
  handleListingAction,
  HaxPublishGray,
  unpublish,
  setEditView,
  handleEticket,
  setIsOpenTicketPopUp,
  display,
  tableRefSide,
  setEditData,
  editData,
}: any) => {
  // new code added
  const [eventRecord, setEventRecord] = useState(tempEventRecord);
  const { isGodAdmin, conversation_rate, priceFactor, isMobile } =
    NoJSXComponent();
  const {
    isEventRecordError,
    isEventRecordBEError,
    isEventRecordHoldTickets,
    isALLTicketUploadedWithPublish,
  } = InventoryNoJSX({
    event,
    eventRecord,
  });
  const { selectedDeliveryTypes, paperTypeExist } = TicketTypeExist(event);

  const calculateRowClasses = () => {
    let rowDisabledClass = "";
    let forRowDisabledClass = "";

    if (
      (tempEventRecord?.selected === false ||
        tempEventRecord?.selected === undefined) &&
      isTableEditable.length > 0
    ) {
      rowDisabledClass = "row-disabled";
    } else if (
      (tempEventRecord?.selected === false ||
        tempEventRecord?.selected === undefined) &&
      isTableEditable?.length === 0
    ) {
      rowDisabledClass = "";
    } else if (
      tempEventRecord?.selected === true &&
      isTableEditable?.length === 0
    ) {
      rowDisabledClass = "";
    } else if (
      tempEventRecord?.selected === true &&
      isTableEditable?.length > 0
    ) {
      rowDisabledClass = "row-indigo-light row-indigo-light -selected";
      forRowDisabledClass = "pointer-events-none !border-gray-300";
    }

    return { rowDisabledClass, forRowDisabledClass };
  };

  const { rowDisabledClass, forRowDisabledClass } = useMemo(
    calculateRowClasses,
    [tempEventRecord?.selected, isTableEditable?.length]
  );

  const layout = useContext(InventoryContext);
  const ticketTypeValue =
    _.size(tempEventRecord) > 0 && getTicketType(tempEventRecord, layout);

  const categoryValue =
    _.size(tempEventRecord) > 0 &&
    _.size(event) > 0 &&
    getCategoryValue(tempEventRecord, event);
  const sectionValue =
    _.size(tempEventRecord) > 0 &&
    _.size(event) > 0 &&
    getSectionValue(tempEventRecord, event);
  const benefitsValue =
    _.size(tempEventRecord) > 0 && getBenefitsValue(tempEventRecord, layout);
  const restrictionValue =
    _.size(tempEventRecord) > 0 && getRestrictionValue(tempEventRecord, layout);

  const faceValue =
    typeof tempEventRecord?.face_value === "object"
      ? tempEventRecord?.face_value?.value
      : decimalNumber(tempEventRecord?.face_value);
  const proccedValue =
    typeof tempEventRecord?.sell_price === "object"
      ? tempEventRecord?.sell_price?.value
      : decimalNumber(tempEventRecord?.sell_price);

  const rowClasses = [
    "view_row",
    "first_row",
    "flex",
    "columns-1",
    "h-[2.5625rem]",
    "border-b",
    "whitespace-nowrap",
    "column-view",
    "items-center",

    tempEventRecord?.bulkUpdate ? "row-disabled !bg-gray-300/40" : "",
    layout?.isBulkActionLoader ? "row-disabled !bg-gray-300/40" : "",
    tempEventRecord?.deleteLoader ? "fade-effect" : "",
    tempEventRecord?.bulkLoader ? "row-disabled" : "",
    paperTypeExist ? "delivery-type-exist" : "",
    layout.tableAlert?.confirm ? "pointer-events-none" : "",
    tempEventRecord?.id === firstSelectedIndex?.id && tempEventRecord?.selected
      ? "first"
      : "",
    event.value === "" ? "row-disabled" : "",
    tempEventRecord?.processing ? "row-processing" : "",
    rowDisabledClass,
    // tempEventRecord?.newRow ? "row-indigo-light ease-out duration-500" : "",
    isEventRecordHoldTickets ? "" : "", // Add your class here
    isEventRecordError || tempEventRecord?.status === "failed" ? "" : "", // Add your class here
    tempEventRecord?.status === "partPublished" ? "" : "", // Add your class here
    layout.isDeleteActive ? "pointer-events-none" : "",
    layout.isEditPopUpActive ? "pointer-events-none" : "",
    tempEventRecord?.selected && layout.confirmRowDelete ? "fade" : "",
    tempEventRecord?.deleteLoader ? "row-disabled" : "",
    tempEventRecord?.loader ||
    (tempEventRecord?.loader &&
      (tempEventRecord?.bulkLoader || layout?.updateAllLoader))
      ? "row-disabled"
      : "",
    layout.selectAllLoader
      ? "!row-disabled !pointer-events-none !bg-gray-300/40"
      : "",
    isGodAdmin && tempEventRecord?.isLock === true ? "bg-gray-300/40" : "",
  ];

  let pngImagePath = "/media/png/";

  const isPaper =
    tempEventRecord?.ticket_type === "paper" ||
    tempEventRecord?.ticket_type?.id === "paper";

  let splitCondition: string = "";
  if (
    (typeof tempEventRecord?.split_type === "string" &&
      tempEventRecord?.split_type !== "MULTIPLES") ||
    (typeof tempEventRecord?.split_type === "object" &&
      tempEventRecord?.split_type?.id !== "MULTIPLES")
  ) {
    splitCondition = "text-gray-400";
  } else {
    splitCondition = "";
  }

  let splitTypeValue: any =
    typeof tempEventRecord?.split_type === "object"
      ? tempEventRecord?.split_type
      : Array.isArray(layout.splitType) &&
        layout.splitType.find(
          (split: any) => split.id === tempEventRecord?.split_type
        );

  const isPublishedWithGod =
    (isALLTicketUploadedWithPublish && !isGodAdmin) ||
    (isGodAdmin &&
      isALLTicketUploadedWithPublish &&
      tempEventRecord?.isLock === false);

  function categoryExist(event: any, record: any, type: any) {
    const categoryValue = record?.category?.id
      ? record?.category
      : event?.categories &&
        event?.categories.find(
          (category: any) => Number(category.id) === Number(record?.category)
        );

    const sectionValue =
      record?.section && record?.section?.id
        ? record?.section
        : record?.section &&
          event?.categories &&
          Array.isArray(event?.categories) &&
          event?.categories
            .find(
              (category: any) =>
                Number(category.id) === Number(record?.category)
            )
            ?.sections?.find(
              (item: any) => Number(item?.id) === Number(record?.section)
            );

    const priceField =
      typeof record?.sell_price === "object"
        ? tempEventRecord?.sell_price?.value
        : record?.sell_price;

    const hasProceedValue = priceField !== "0.00" && priceField !== "";
    const hasNoCategory = _.size(categoryValue) === 0;
    const hasNoSection = _.size(sectionValue) === 0;

    if (type === "withoutcategory") {
      return _.size(record?.markinsight) > 0
        ? hasProceedValue && (hasNoCategory || hasNoSection)
        : false;
    }
    if (type === "withcategory") {
      return _.size(record?.markinsight) > 0
        ? hasProceedValue &&
            _.size(categoryValue) > 0 &&
            _.size(sectionValue) > 0
        : false;
    }
  }

  const [updateChecker, setUpdateChecker] = useState<any>();

  const tempEventRecordMemo = useMemo(() => {
    return tempEventRecord;
  }, [tempEventRecord]);

  useEffect(() => {
    if (layout.refreshList || tempEventRecordMemo) {
      setEventRecord(tempEventRecordMemo);
    }
  }, [tempEventRecordMemo, layout.refreshList]);

  const MemoizedHandleTableFieldOld = useMemo(() => {
    const MemoizedHandleTableField = (data: any) => {
      let tempEvents = list;
      // If table is not edit-able
      if (layout.isTableEditable.length === 0) {
        // REPLACIBLE RECORD
        if (
          !tempEvents[eIndex]["eventRecords"][rIndex]["replacible"] ||
          _.size(tempEvents[eIndex]["eventRecords"][rIndex]["replacible"]) === 0
        ) {
          tempEvents[eIndex]["eventRecords"][rIndex]["replacible"] = {
            ...tempEvents[eIndex]["eventRecords"][rIndex],
          };
        }

        tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
          data.target.value !== undefined && Array.isArray(data.target.value)
            ? data.target.value
            : typeof data.target.value === "object"
            ? data.target.value
            : typeof data.target.value === "string" &&
              data.target.type !== "checkbox"
            ? data.target.value
            : data.target.type === "checkbox"
            ? data.target.checked
            : data.target.value;

        //for the category change
        if (data.target.name === "category") {
          tempEvents[eIndex]["eventRecords"][rIndex]["section"] = null;
        }
        if (data.target.name === "section") {
          const avg_price = calculateAvgPriceForProceedValue(
            data,
            tempEvents[eIndex]["eventRecords"][rIndex]["markinsight"],
            tempEvents[eIndex]["eventRecords"][rIndex],
            tempEvents[eIndex],
            conversation_rate,
            isGodAdmin
          );

          tempEvents[eIndex]["eventRecords"][rIndex]["avg_price"] = avg_price;
        }

        if (
          data.target.type === "checkbox" &&
          data.target.name === "selected" &&
          _.size(tempEvents[eIndex]?.["requireSelectAll"]) > 0
        ) {
          delete tempEvents[eIndex]["requireSelectAll"];
        }

        data.target.name !== "selected" &&
          (tempEvents[eIndex]["eventRecords"][rIndex]["isInputChange"] = true);

        // data.target.name === "ticket_type" &&
        //   data.target?.value?.id !== "eticket" &&
        //   (tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
        //     "");
        // WHEN TICKIT TYPE IS EMPTY THEN SLECTED SUBTICKIT TYPE FIRST BY DEFAULT
        data.target.name === "ticket_type" &&
          data.target?.value?.id === "eticket" &&
          tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] ===
            "" &&
          (tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
            subTicketType?.[0]);

        data.target.name === "split_type" &&
          data.target.value?.id !== "MULTIPLES" &&
          (tempEvents[eIndex]["eventRecords"][rIndex]["split_quantity"] = 0);

        //for the eticket change
        if (data.target.name === "ticket_type") {
          //for delivery type field
          if (data.target.value?.id !== "paper") {
            tempEvents[eIndex]["eventRecords"][rIndex]["delivery_options"] = [];
          }
        }
        if (data.target.name === "ticketsInHands" && data.target?.checked) {
          tempEvents[eIndex]["eventRecords"][rIndex]["date_to_ship"] =
            new Date();
        }

        // GET SUM OF QUANTITY
        // const sumQuantityAvailable = _.sumBy(tempEvents, (event) =>
        //   _.sumBy(event.eventRecords, (record: any) => {
        //     const quantityAvailable = parseInt(record.quantity_available, 10);
        //     return Number.isNaN(quantityAvailable) ? 0 : quantityAvailable;
        //   })
        // );
        // tempEvents[eIndex]["total_qty_available"] = sumQuantityAvailable;
        // id? "eticket" =  ticket_type

        let ticketType =
          tempEvents[eIndex]["eventRecords"][rIndex]?.["ticket_type"];

        let quantity_available =
          tempEvents[eIndex]["eventRecords"][rIndex]?.quantity_available;

        let visibility: any = visibilityMeterCalculationForInventory(
          tempEvents[eIndex],
          tempEvents[eIndex]["eventRecords"][rIndex],
          layout.ticketType
        );

        const currentVisibility =
          tempEvents[eIndex]["eventRecords"][rIndex]["visibility"];
        const oldVisibility =
          currentVisibility === visibility
            ? tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"]
            : currentVisibility;

        tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"] =
          oldVisibility;
        tempEvents[eIndex]["eventRecords"][rIndex]["visibility"] = visibility;
        //setEventRecord(tempEvents[eIndex]["eventRecords"][rIndex]);
        setList(tempEvents);
        // If table is edit-able
      } else {
        setList((prev: any) => {
          return prev.map((innerEvent: any, eventIndex: number) => {
            if (event.id === innerEvent.id) {
              let eventRecords: any = Array.isArray(innerEvent["eventRecords"])
                ? event["eventRecords"].map((eventRecord: any) => {
                    if (eventRecord?.selected === true) {
                      let newArrDelivery = layout.deliveryType?.reduce(
                        (count: any, item: any) => {
                          count[item.id] = item.type;
                          return count;
                        },
                        {}
                      );

                      layout.setTableDataEditChanging((pre: any[]) => {
                        const eventPre = pre[eIndex] || [];
                        const conditions: any = newArrDelivery;
                        const newName = conditions[data.target.name] || null;

                        let updatedEventPre = [...eventPre];

                        if (
                          newName &&
                          updatedEventPre.indexOf(newName) === -1 &&
                          data.target.name
                        ) {
                          updatedEventPre = [
                            ...updatedEventPre,
                            newName,
                            data.target.name,
                          ];
                        } else if (
                          !newName &&
                          updatedEventPre.indexOf(data.target.name) === -1 &&
                          data.target.name
                        ) {
                          updatedEventPre = [
                            ...updatedEventPre,
                            data.target.name,
                          ];
                        }

                        const newPre = [...pre];
                        newPre[eIndex] = updatedEventPre;

                        return newPre;
                      });

                      //for split type
                      let multipleData = eventRecord?.split_quantity;
                      if (
                        data.target.name === "split_type" &&
                        data.target.value?.id !== "MULTIPLES"
                      ) {
                        multipleData = "";
                      }

                      let final = {
                        ...eventRecord,
                        split_quantity: multipleData,
                        replacible:
                          _.size(eventRecord?.replacible) === 0
                            ? eventRecord
                            : eventRecord?.replacible,
                        [data.target.name]:
                          data.target.value !== undefined &&
                          Array.isArray(data.target.value)
                            ? data.target.value
                            : typeof data.target.value === "object"
                            ? data.target.value
                            : typeof data.target.value === "string" &&
                              data.target.type !== "checkbox"
                            ? data.target.value
                            : data.target.type === "checkbox"
                            ? data.target.checked
                            : data.target.value,
                        ...(data.target.name !== "selected"
                          ? { isInputChange: true }
                          : {}),
                      };

                      return {
                        ...final,
                        ...(final?.ticketsInHands
                          ? { date_to_ship: new Date() }
                          : { date_to_ship: final?.date_to_ship }),
                      };
                    } else {
                      return eventRecord;
                    }
                  })
                : innerEvent["eventRecords"];

              const final = eventRecords?.map((record: any, index: number) => {
                let newArrDelivery = eventRecord?.delivery_options?.reduce(
                  (count: any, item: any) => {
                    count[item.id] = item.type;
                    return count;
                  },
                  {}
                );

                if (record?.selected) {
                  // console.log(record, "record");
                  if (record?.section?.id) {
                    // const sectionValue =
                    // eventRecord?.section && eventRecord?.section?.id
                    //   ? eventRecord?.section
                    //   : eventRecord?.section &&
                    //     event?.categories &&
                    //     Array.isArray(event?.categories) &&
                    //     event?.categories
                    //       .find(
                    //         (category: any) =>
                    //           Number(category.id) === Number(eventRecord?.category)
                    //       )
                    //       ?.sections?.find(
                    //         (item: any) => Number(item?.id) === Number(eventRecord?.section)
                    //       );

                    let categoryArray: any = record.category;
                    if (typeof categoryArray === "string") {
                      categoryArray = innerEvent?.categories?.find(
                        (obj: any) => Number(obj?.id) === Number(categoryArray)
                      );
                    }
                    const matchingId = record?.section?.id;
                    const matchingSections = categoryArray?.sections?.filter(
                      (innerObj: any) => innerObj?.id === matchingId
                    );

                    if (!matchingSections || matchingSections?.length === 0) {
                      record.section = "";
                    }
                  }

                  if (firstSelectedIndex === -1) {
                    // This is the first selected record, store its index
                    firstSelectedIndex = index;
                    return {
                      ...record,
                    };
                  } else {
                    // Check if ticketType is "paper"
                    if (
                      record.ticket_type?.id === "paper" ||
                      record.ticket_type === "paper"
                    ) {
                      return {
                        ...record,
                      };
                    } else {
                      // Create a copy of the record without the specific key
                      // const {
                      //   "01fg98zqgrtfe7avqvv575e970": _,
                      //   "01fnv9gwfd8wmnn27aw196pj37": __,
                      //   "01fnv9ja884j9empp7a4z663wj": ___,
                      //   ...rest
                      // } = record;
                      const keysToDelete = Object.keys(newArrDelivery);
                      let rest = record;

                      keysToDelete.forEach((key: any) => {
                        if (rest.hasOwnProperty(key)) {
                          delete rest[key];
                        }
                      });
                      return {
                        ...rest,
                        delivery_options: [],
                      };
                    }
                  }
                }

                return record;
              });

              // console.log(final?.date_to_ship);
              return {
                ...innerEvent,
                eventRecords: final?.map((obj: any) => {
                  // VISIBILITY LOGIC
                  let visibility: any = visibilityMeterCalculationForInventory(
                    event,
                    obj,
                    layout?.ticketType
                  );
                  let avg_price: any;

                  avg_price = calculateAvgPriceForProceedValue(
                    obj?.section,
                    obj?.["markinsight"],
                    obj,
                    event,
                    conversation_rate,
                    isGodAdmin
                  );
                  return {
                    ...obj,
                    visibility: visibility,
                    oldVisibility:
                      obj?.visibility === visibility
                        ? obj?.oldVisibility
                        : obj?.visibility,
                    avg_price: avg_price,
                  };
                }),
              };
            } else {
              return innerEvent;
            }
          });
        });
        // setTimeout(() => {
        //   setList((current: any) => {
        //     layout.setIsLoader(false);

        //     return current;
        //   });
        // }, 500);
      }
    };

    return MemoizedHandleTableField;
  }, [eIndex, event, layout, list, rIndex, setList, subTicketType]);
  const MemoizedHandleTableField = useMemo(() => {
    return (data: any, eIndex: number, rIndex: number) => {
      setEventRecord((prev: any) => {
        return {
          ...prev,
          [data?.target?.name]:
            data.target.value !== undefined && Array.isArray(data.target.value)
              ? data.target.value
              : typeof data.target.value === "object"
              ? data.target.value
              : typeof data.target.value === "string" &&
                data.target.type !== "checkbox"
              ? data.target.value
              : data.target.type === "checkbox"
              ? data.target.checked
              : data.target.value,
        };
      });

      if (layout.isTableEditable.length === 0) {
        // SINGLE EDIT
        MemoizedHandleTableFieldOld(data);
        setUpdateChecker({
          eventId: eIndex,
          eventRecordId: rIndex,
        });
      } else {
        // MULTI EDIT
        setUpdateChecker(data);
      }
    };
  }, [MemoizedHandleTableFieldOld, layout.isTableEditable.length]);

  //SINGLE EDIT
  useEffect(() => {
    if (layout.isTableEditable.length === 0) {
      setTimeout(() => {
        updateChecker?.eventId !== undefined &&
          setEventRecord(
            list?.[updateChecker?.eventId]?.eventRecords?.[
              updateChecker?.eventRecordId
            ]
          );
      }, 1000);
    }
  }, [layout.isTableEditable.length, list, updateChecker]);
  // MULTI EDIT
  let eventRecordMemo: any = useMemo(() => {
    return [updateChecker];
  }, [updateChecker]);

  function refreshEventsWhileActionPerform() {
    //send response to eventField component
    const tempEvents = (events: any) => {
      return events.map((event: any) => {
        return {
          ...event,
          eventRecords:
            Array.isArray(event["eventRecords"]) &&
            event["eventRecords"].length > 0
              ? event["eventRecords"].map((tempEventRecord: any) => {
                  return {
                    ...tempEventRecord,
                  };
                })
              : undefined,
        };
      });
    };
    setList(tempEvents([...list]));
  }

  const editViewPopOpen = (record: any, event: any) => {
    const element = document.getElementById("filtersMob1");
    if (element) {
      element.classList.add("activeFIlter");
      document.body.classList.add("overflow-hidden-custom");
      setEditView({
        show: true,
        record: record,
        event: event,
      });
    }
  };

  const handleOnSingleUnLock = (e: any, eId: any, sIndex: any) => {
    setList((prev: any) => {
      return prev.map((event: any, eInnerIndex: any) => {
        if (event?.id === eId) {
          return {
            ...event,
            eventRecords: Array.isArray(event["eventRecords"])
              ? event["eventRecords"].map((eventRecord: any, index: any) => {
                  if (rIndex === index) {
                    return {
                      ...eventRecord,
                      isLock: !eventRecord?.isLock,
                      selected: false,
                    };
                  } else {
                    return { ...eventRecord };
                  }
                })
              : [],
          };
        } else {
          return event;
        }
      });
    });
  };

  //CANCEL EVENT RECORD
  const cancelRecord = (currentEventRecord: any) => {
    let tempErecord = {
      ...eventRecord,
      ...eventRecord?.replacible,
      isInputChange: false,
      loader: false,
      selected: false,
      replacible: {},
      fieldErrors: {},
    };
    setEventRecord(tempErecord);

    setList((prev: any) => {
      return prev.map((event: any, inEIndex: any) => {
        if (inEIndex === eIndex) {
          return {
            ...event,
            eventRecords:
              event?.eventRecords &&
              event?.eventRecords.map((eventRecord: any) => {
                if (eventRecord?.id === currentEventRecord?.id) {
                  let tempErecord = {
                    ...eventRecord,
                    ...eventRecord?.replacible,
                    isInputChange: false,
                    loader: false,
                    selected: false,
                    replacible: {},
                    fieldErrors: {},
                  };
                  return tempErecord;
                } else {
                  return eventRecord;
                }
              }),
          };
        } else {
          return event;
        }
      });
    });
  };
  //SET EVENT RECORD LOADER
  const setEventRecordLoader = async (eventRecord: any, status: boolean) => {
    setList((events: any) => {
      return events.map((inEvent: any) => {
        if (inEvent.id === event.id) {
          return {
            ...inEvent,
            eventRecords: Array.isArray(inEvent["eventRecords"])
              ? inEvent["eventRecords"].map((inEventRecord: any) => {
                  if (eventRecord?.id === inEventRecord?.id) {
                    return {
                      ...inEventRecord,
                      loader: status,
                    };
                  } else {
                    return inEventRecord;
                  }
                })
              : [],
          };
        } else {
          return inEvent;
        }
      });
    });
  };

  const [singleRecordLoader, setSetSingleRecordLoader] = useState(false);

  // UPDATE EVENT RECORD
  const updateRecord = async (
    data: any,
    eventRecord: any,
    onlyUpdate: Boolean = false,
    evIndex: any,
    rcInxex: any
  ) => {
    //CHECK LOCAL VALIDATION
    if (!(await setLocalValidationToEventRecords(eventRecord?.id, false))) {
      setEventRecordLoader(eventRecord, false);
      return true;
    } else {
      setTimeout(() => {
        setLocalValidationToEventRecords(eventRecord?.id, false);
        eventRecord.status = onlyUpdate
          ? eventRecord?.status
          : eventRecord?.status === "no"
          ? "yes"
          : "no";
        setSetSingleRecordLoader(true);
        layout.setAnimationForVisibility({
          eIndex: evIndex,
          rIndex: rcInxex,
          flag: true,
        });
        UpdateInventory(
          setEventRecordPayload(
            event,
            { ...eventRecord, price_per_exchange: 0 } //REMOVE PPE CHANGE
          )
        ).then((response: any) => {
          let responseData = response?.data?.payload?.post_data;
          responseData && responseData.status
            ? showAlert(response.message, false)
            : showAlert(Object.values(response?.errors)?.toString(), true);

          setTimeout(() => {
            setSetSingleRecordLoader(false);
            layout.setAnimationForVisibility({
              eIndex: evIndex,
              rIndex: rcInxex,
              flag: false,
            });
          }, 3000);

          // setEventRecord({
          //   ...eventRecord,
          //   visibility: 0,
          //   selected: false,
          //   oldVisibility: 0,
          //   loader: false,
          //   isInputChange: false,
          //   replacible: {},
          // });

          //UPDATE VISBILITY STATE
          updateVisibility({
            ...eventRecord,
            ...(response?.data?.payload?.is_ticket_type_change === 1
              ? { listing_ticket_files: [], ticketFiles: [], mobile_links: [] }
              : {}),
          });

          setTimeout(() => {
            //setIsLoading(false);
          }, 100);
        });
      }, 600);
    }
  };

  const setLocalValidationToEventRecords = async (id: any, isPublish: any) => {
    let success = true;
    setList((events: any) => {
      return events.map((inEvent: any) => {
        if (inEvent?.id === event?.id) {
          return {
            ...inEvent,
            eventRecords: Array.isArray(inEvent["eventRecords"])
              ? event["eventRecords"].map(
                  (eventRecord: any, eventRecordKey: any) => {
                    if (eventRecord?.id === id) {
                      let fieldErrors: any = {};

                      const faceValueCheck =
                        Number(eventRecord?.face_value) <= 0 ||
                        (typeof eventRecord?.face_value === "object" &&
                          Number(eventRecord?.face_value?.value) <= 0);

                      const processValueCheck =
                        Number(eventRecord?.sell_price) <= 0 ||
                        (typeof eventRecord?.sell_price === "object" &&
                          Number(eventRecord?.sell_price?.value) <= 0 &&
                          !eventRecord?.ppe);

                      const isHoldTicket =
                        eventRecord?.hold_tickets?.length > 0 &&
                        (eventRecord?.listing_ticket_files?.length > 0 ||
                          eventRecord?.mobile_links?.length > 0) &&
                        Number(eventRecord?.quantity_available) !==
                          (Number(eventRecord?.listing_ticket_files?.length) ||
                            Number(eventRecord?.mobile_links?.length));

                      const isUploadedTicketLessWithQuantity = isPublish
                        ? (eventRecord?.listing_ticket_files?.length > 0 ||
                            eventRecord?.mobile_links?.length > 0) &&
                          eventRecord?.quantity_available !==
                            (eventRecord?.listing_ticket_files?.length ||
                              eventRecord?.mobile_links?.length)
                        : eventRecord?.quantity_available &&
                          eventRecord?.quantity_available <
                            (eventRecord?.listing_ticket_files?.length ||
                              eventRecord?.mobile_links?.length);

                      !eventRecord?.ticket_type &&
                        (fieldErrors = {
                          ...fieldErrors,
                          ticket_type: "Ticket type field is required",
                        });

                      (eventRecord?.ticket_type === "paper" ||
                        eventRecord?.ticket_type?.id === "paper") &&
                        eventRecord?.delivery_options?.length > 0 &&
                        eventRecord?.delivery_options?.map(
                          (singleDelivery: any) => {
                            let price =
                              eventRecord?.[singleDelivery?.id] != undefined
                                ? Number(eventRecord?.[singleDelivery?.id])
                                : Number(singleDelivery?.price);
                            (!price || price <= 0) &&
                              (fieldErrors = {
                                ...fieldErrors,
                                [singleDelivery?.id]:
                                  layout.deliveryType?.find(
                                    (item: any) =>
                                      singleDelivery?.id === item?.id
                                  )?.type + " field is required more than zero",
                              });
                          }
                        );

                      (!eventRecord?.quantity_available ||
                        isUploadedTicketLessWithQuantity ||
                        isHoldTicket) &&
                        (fieldErrors = {
                          ...fieldErrors,
                          quantity_available:
                            isUploadedTicketLessWithQuantity || isHoldTicket
                              ? TicketError
                              : "Quantity field is required",
                        });

                      !eventRecord?.category &&
                        event?.categories &&
                        Object.keys(event?.categories).length > 0 &&
                        (fieldErrors = {
                          ...fieldErrors,
                          category: "Category value field is required",
                        });

                      (!eventRecord?.face_value ||
                        (typeof eventRecord?.face_value === "object" &&
                          eventRecord?.face_value?.value === "")) &&
                        (fieldErrors = {
                          ...fieldErrors,
                          face_value: "Face value field is required",
                        });

                      //  new
                      faceValueCheck &&
                        (fieldErrors = {
                          ...fieldErrors,
                          face_value:
                            "Face value field is required more than zero",
                        });

                      (!eventRecord?.sell_price ||
                        (typeof eventRecord?.sell_price === "object" &&
                          eventRecord?.sell_price?.value === "")) &&
                        !eventRecord?.ppe &&
                        (fieldErrors = {
                          ...fieldErrors,
                          sell_price: "Proceed value field is required",
                        });

                      // new

                      processValueCheck &&
                        (fieldErrors = {
                          ...fieldErrors,
                          sell_price:
                            "Proceed value field is required more than zero",
                        });

                      // !eventRecord?.section &&
                      //   eventRecord?.category?.sections &&
                      //   Object.keys(eventRecord?.category?.sections).length > 0 &&
                      //   (fieldErrors = {
                      //     ...fieldErrors,
                      //     section: "Section value field is required",
                      //   });
                      !eventRecord?.split_type &&
                        (fieldErrors = {
                          ...fieldErrors,
                          splitType: "Split Type value field is required",
                        });
                      !eventRecord?.MULTIPLES &&
                        eventRecord?.splitType?.id === "MULTIPLES" &&
                        (fieldErrors = {
                          ...fieldErrors,
                          MULTIPLES: "Sell in Multiples Field is required",
                        });

                      !eventRecord?.sub_ticket_type?.id &&
                        eventRecord?.ticketType?.id === "eticket" &&
                        (fieldErrors = {
                          ...fieldErrors,
                          sub_ticket_type: "Sub Ticket type Field is required",
                        });

                      // eventRecord?.date_to_ship &&
                      //   moment(event?.date).valueOf() <
                      //   moment(eventRecord?.date_to_ship).valueOf() &&
                      //   (fieldErrors = {
                      //     ...fieldErrors,
                      //     date_to_ship:
                      //       "Date of ship should not be greater than the event date",
                      //   });

                      Object.keys(fieldErrors).length > 0 && (success = false);

                      let errors: any = [
                        {
                          errors: [
                            ...(eventRecord?.errors &&
                            _.size(eventRecord?.errors) > 0
                              ? eventRecord?.errors?.[0]?.errors
                              : []),
                            ...(fieldErrors && _.size(fieldErrors) > 0
                              ? Object.keys(fieldErrors)
                              : []),
                          ],
                          exchange: eventRecord?.errors?.[0]?.exchange,
                        },
                      ];
                      let [firstKey] = Object.keys(fieldErrors);
                      setTimeout(() => {
                        let inputFields: any = document.getElementById(
                          `${firstKey}-${event.id}-${eventRecordKey}`
                        );
                        if (inputFields) {
                          inputFields.scrollIntoView({
                            behavior: "auto",
                            inline: "center",
                            block: `center`,
                          });
                          showAlert(
                            "Please fix the errors on the listings",
                            true
                          );
                        }
                      }, 500);
                      return {
                        ...eventRecord,
                        fieldErrors: fieldErrors,
                        ...(_.size(errors?.[0]?.errors) > 0 && {
                          errors: errors,
                        }),
                      };
                    } else {
                      return eventRecord;
                    }
                  }
                )
              : inEvent["eventRecords"],
          };
        } else {
          return inEvent;
        }
      });
    });
    return success;
  };

  function checkIsFullfillUploadExist(event: any, eventRecord: any) {
    const selectedEventRecords = list
      ?.flatMap((item: any) =>
        item?.eventRecords?.filter(
          (record: any) =>
            record?.selected &&
            (record?.ticket_type === "eticket" ||
              record?.ticket_type?.id === "eticket" ||
              record?.ticket_type?.id === "pdf" ||
              record?.ticket_type?.id === "mobile-qr" ||
              record?.ticket_type?.id === "mobile-link" ||
              record?.ticket_type?.id === "mobile-link")
        )
      )
      .filter((obj: any) => obj !== undefined);

    const flag =
      selectedEventRecords?.length > 1
        ? selectedEventRecords?.filter(
            (obj: any) =>
              obj?.quantity_available ===
                (obj?.listing_ticket_files?.length ||
                  obj?.mobile_links?.length) && obj?.status === "yes"
          )
        : [];
    return flag || "";
  }
  return (
    <>
      <div
        className={`view_row mobileRow ${
          isEventRecordError ||
          tempEventRecord?.status === "failed" ||
          isEventRecordBEError
            ? "row-error-new group-hover:bg-rose-550"
            : ""
        } ${
          isEventRecordHoldTickets
            ? "group-hover:bg-sky-blue-300 row-hold-new"
            : ""
        } ${tempEventRecord?.processing ? "row-processing-new" : ""} ${
          isPublishedWithGod && "bg-white"
        }`}
        // onClick={() => {
        //   setCurrentRecordId({
        //     recordIndex: rIndex,
        //     eventIndex: eIndex,
        //     isActive: true,
        //   });
        // }}
        onMouseEnter={() => {
          // if (
          //   !(layout.isTableEditable?.length > 0 && tempEventRecord?.selected) ||
          //   !tempEventRecord?.selected
          // ) {
          //   setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
          // }
          if (layout.isTableEditable?.length === 0 && !isMobile) {
            setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
          }
        }}
        onMouseLeave={(e: any) => {
          if (layout.isTableEditable?.length > 0 && !isMobile) {
            setCurrentRecordId(undefined);
          }
        }}
        onTouchStart={() => {
          if (
            !(
              layout.isTableEditable?.length > 0 && !tempEventRecord?.selected
            ) &&
            !isMobile
          ) {
            setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
          }

          // }
        }}
      >
        <div className={`${rowClasses.join(" ")}`}>
          {isGodAdmin && (
            <div
              className={`lock-img-view td_block checkbox_td td_block zero-col z-[3] ${
                isPublishedWithGod && "bg-white"
              }`}
              id="checkbox_shad_lock"
            >
              <div
                className="lock-icon relative w-full h-full border-r  "
                onClick={(e) => handleOnSingleUnLock(e, event?.id, rIndex)}
              >
                <label
                  className={`cursor-pointer w-full h-full flex items-center justify-center ${
                    isGodAdmin && tempEventRecord?.isLock === true
                      ? " lock"
                      : "unlock"
                  }`}
                >
                  <Image
                    path={
                      tempEventRecord?.isLock === true
                        ? `${pngImagePath}lock.png`
                        : `${pngImagePath}unlock.png`
                    }
                    className="w-[.8125rem] h-[.9375rem]"
                  />
                </label>
              </div>
            </div>
          )}
          <div
            className={clsx(
              "td_block checkbox_td first-col bg-inherit z-[3] overflow-hidden",
              isGodAdmin ? "left-10" : "left-0",
              isPublishedWithGod && "bg-white",
              isGodAdmin && tempEventRecord?.isLock && "pointer-events-none"
            )}
          >
            <label
              className={clsx(
                "bg-inherit select-none w-full h-full top-0 left-0 absolute cursor-pointer p-1 border-r group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]",
                forRowDisabled,
                disabledAll && "pointer-events-none",
                tempEventRecord?.selected && "bg-indigo-500 bg-opacity-[7%]",
                (tempEventRecord?.errors &&
                  Object.keys(tempEventRecord?.errors).length > 0) ||
                  tempEventRecord?.status === "failed"
                  ? "row-error-new bg-opacity-[5%] !group-hover:bg-rose-550 group-hover:bg-opacity-[5%]"
                  : "",
                (isEventRecordError ||
                  tempEventRecord?.status === "failed" ||
                  isEventRecordBEError) &&
                  "row-error-new !group-hover:bg-rose-550"
              )}
              htmlFor={`checkbox-all-${eIndex}${rIndex}`}
            >
              {eventRecord?.cancelled_order_id && (
                <span className="triangle-up"></span>
              )}
            </label>
            <input
              name="selected"
              id={`checkbox-all-${eIndex}${rIndex}`}
              type="checkbox"
              checked={tempEventRecord?.selected ? true : false}
              className={`${forRowDisabledClass} ${
                (disabledAll || (isGodAdmin && tempEventRecord?.isLock)) &&
                "pointer-events-none "
              }`}
              onChange={(event: any) =>
                MemoizedHandleTableField(event, eIndex, rIndex)
              }
              onClick={(e) => {
                handleShift(e, rIndex, event?.eventRecords);
                setTimeout(() => {
                  refreshEventsWhileActionPerform();
                }, 70);
              }}
            />
          </div>
          {/* BLOCKS */}
          <RenderList
            columns={getColumnItems}
            event={event}
            eventRecord={eventRecord}
          />

          <div
            className={`twentythree-col ${
              layout.proceedPriceToggle &&
              !isMobile &&
              "fixed_price_proceed !min-w-[20.75rem]"
            } ${
              isGodAdmin && tempEventRecord?.isLock ? "pointer-events-none" : ""
            }`}
          >
            {layout.isTableEditable?.length === 0 &&
            tempEventRecord?.isInputChange ? (
              <div
                className={clsx(
                  "accordion-body left-shad",
                  (tempEventRecord?.loader || layout?.isBulkActionLoader) &&
                    "list-loading",
                  layout.proceedPriceToggle &&
                    !isMobile &&
                    "!justify-between !p-0"
                )}
                id="left-shad-list"
              >
                {layout.proceedPriceToggle && !isMobile && (
                  <div
                    className="eighteen-col flex items-center flex-1 !max-w-[8.1819rem] border-r !px-2.5 !py-0 h-full"
                    data-sybmol-attribute={
                      tempEventRecord?.sell_price_currency
                        ? SYMBOLS[tempEventRecord?.sell_price_currency]
                        : SYMBOLS[tempEventRecord?.sell_price?.symbol]
                    }
                  >
                    {proccedValue ? proccedValue : "Proceed Price"}
                  </div>
                )}
                <div className="btn-wrap flex items-center flex-1 justify-center">
                  {/* CANCEL RECORD BUTTON */}
                  <Button
                    className="cancel-view"
                    onClick={() => cancelRecord(eventRecord)}
                  >
                    <Image
                      path={`${pngImagePath}cross.png`}
                      className="w-[.5966rem] h-[.5966rem]"
                    />
                  </Button>

                  {/* UPDATE RECORD BUTTON */}
                  <Button
                    className="upload-view"
                    onClick={(data: any) => {
                      eventRecord.loader = true;
                      updateRecord(data, eventRecord, true, eIndex, rIndex);
                    }}
                  >
                    <Image
                      path={`${pngImagePath}Check.png`}
                      className="w-[.7585rem] h-[.6452rem]"
                    />
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  "st_icons left-shad",
                  isPublishedWithGod && "bg-white",
                  isEventRecordHoldTickets &&
                    "row-hold-new group-hover:bg-sky-blue-300",
                  (isEventRecordError || isEventRecordBEError) &&
                    "row-error-new group-hover:bg-rose-550",
                  layout.proceedPriceToggle &&
                    !isMobile &&
                    "flex justify-between"
                )}
                id="left-shad-list"
              >
                {layout.proceedPriceToggle && !isMobile && (
                  <div
                    className="eighteen-col flex items-center flex-1 !max-w-[8.1819rem] border-r !px-2.5 !py-0"
                    data-sybmol-attribute={
                      tempEventRecord?.sell_price_currency
                        ? SYMBOLS[tempEventRecord?.sell_price_currency]
                        : SYMBOLS[tempEventRecord?.sell_price?.symbol]
                    }
                  >
                    {proccedValue ? proccedValue : "Proceed Price"}
                  </div>
                )}
                <div className={`icon-wrap`}>
                  {/* PPP */}
                  <button
                    className={`${
                      isMobile && "pointer-events-none"
                    }  group flex flex-auto items-center border-r justify-center p-1 md:p-1 w-10 md:w-10 h-[2.5rem] `}
                    type="button"
                    id={`price-per-hand-tooltip${eIndex}${eventRecord?.card}`}
                  >
                    <IKTSVG
                      path={PPPIcon}
                      className={
                        eventRecord?.price_per_exchange || eventRecord?.ppe
                          ? "fill-green-500"
                          : "fill-violet-400"
                      }
                      svgClassName={`w-[.9688rem] h-[.9688rem] group-hover:fill-indigo-500 `}
                    />
                  </button>

                  <button
                    className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-10 md:max-w-[2.5rem] h-[2.5rem] border-r ${
                      isEventRecordHoldTickets && "pointer-events-none"
                    }`}
                    type="button"
                    // onClick={() => {
                    //   // ticketInHandFromListings(eIndex, rIndex);

                    //   let target = {
                    //     name: "ticketsInHands",
                    //     checked: !eventRecord?.ticketsInHands,
                    //     type: "checkbox",
                    //   };
                    //   MemoizedHandleTableField({ target }, eIndex, rIndex);
                    // }}
                  >
                    {tempEventRecord?.ticketsInHands ? (
                      <IKTSVG
                        path={handIcon}
                        svgClassName={` w-3 group-hover:fill-indigo-500 ${
                          Number(tempEventRecord?.quantity_available) > 0 &&
                          Number(tempEventRecord?.quantity_available) ===
                            (Number(
                              tempEventRecord?.listing_ticket_files?.length
                            ) || Number(tempEventRecord?.mobile_links?.length))
                            ? "fill-gray-400"
                            : "fill-green-600"
                        } `}
                      />
                    ) : (
                      <IKTSVG
                        path={handCross}
                        svgClassName="fill-violet-500 w-3 group-hover:fill-indigo-500"
                      />
                    )}
                  </button>
                  <button
                    className={`upload-img-view  ${
                      isEventRecordHoldTickets && "pointer-events-none"
                    }`}
                    type="button"
                    onClick={(e: any) => {
                      layout.setLogsData(null);
                      const isUploaded = checkIsFullfillUploadExist(
                        event,
                        eventRecord
                      );
                      if (
                        eventRecord?.ticket_type?.id !== "mobile-qr" &&
                        eventRecord?.ticket_type?.id !== "pdf" &&
                        eventRecord?.ticket_type !== "eticket" &&
                        eventRecord?.ticket_type !== "mobile-link" &&
                        eventRecord?.ticket_type?.id !== "mobile-link"
                      ) {
                        e.preventDefault();
                      } else {
                        if (isUploaded?.length === 0) {
                          handleEticket(event, eventRecord);
                          setIsOpenTicketPopUp(true);
                          if (editData?.status === true) {
                            display(false, tableRefSide?.current?.style);
                            setEditData({ status: false });
                          }
                        } else {
                          showAlert(
                            "Bulk upload selection can not include listings with tickets already uploaded",
                            true,
                            3000
                          );
                        }
                      }
                    }}
                  >
                    <img
                      // src={`${pngImagePath}upload.png`}
                      src={`${
                        tempEventRecord?.ticket_type?.id !== "mobile-qr" &&
                        tempEventRecord?.ticket_type?.id !== "pdf" &&
                        tempEventRecord?.ticket_type !== "eticket" &&
                        tempEventRecord?.ticket_type !== "mobile-link" &&
                        tempEventRecord?.ticket_type?.id !== "mobile-link"
                          ? `${pngImagePath}upload-disable.png`
                          : Number(tempEventRecord?.quantity_available) > 0 &&
                            Number(tempEventRecord?.quantity_available) ===
                              (Number(
                                tempEventRecord?.listing_ticket_files?.length
                              ) ||
                                Number(tempEventRecord?.mobile_links?.length))
                          ? `${pngImagePath}upload-success.png`
                          : tempEventRecord?.fieldErrors?.[
                              "quantity_available"
                            ] === TicketError
                          ? `${pngImagePath}upload-error.png`
                          : `${pngImagePath}upload.png`
                      }`}
                      alt="upload"
                      className="w-[.8125rem] h-[.6875rem]"
                    />
                  </button>

                  <button
                    className={`flex flex-auto items-center justify-center p-1 w-10 md:max-w-[2.5rem] h-[2.5rem] border-r`}
                    type="button"
                    id="visibility_addInventory"
                    onClick={() => {
                      layout.setLogsData(null);
                      setIsOpenListingQualityPopup(true);
                    }}
                  >
                    <div
                      id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                      className={` table-meter flex gap-[.0875rem] svg-wrap
                       
                       ${
                         categoryExist(event, tempEventRecord, "withcategory")
                           ? colorForMeter(
                               tempEventRecord?.avg_price
                                 ? priceCalculation(
                                     typeof tempEventRecord?.sell_price ===
                                       "object"
                                       ? tempEventRecord?.sell_price?.value
                                       : tempEventRecord?.sell_price,
                                     tempEventRecord?.avg_price
                                       ? Number(tempEventRecord?.avg_price)
                                       : null,
                                     priceFactor
                                   )
                                 : false
                             )
                           : ""
                       } 
                       
                       ${
                         layout.animationForVisibility?.eIndex === eIndex &&
                         layout.animationForVisibility?.rIndex === rIndex &&
                         layout.animationForVisibility?.flag
                           ? ` visibility-case${
                               tempEventRecord?.oldVisibility > 2
                                 ? tempEventRecord?.oldVisibility - 2
                                 : tempEventRecord?.oldVisibility
                             }-${visiblityMeterForBelowListing(
                               tempEventRecord?.oldVisibility,
                               tempEventRecord?.visibility
                             )}`
                           : `visibility-case${visiblityMeterForBelowListing(
                               tempEventRecord?.oldVisibility,
                               tempEventRecord?.visibility
                             )}`
                       }
                       `}
                    >
                      <span className="s_block w-[2px] h-3 bg-gray-300 rounded-l-[1px]"></span>
                      <span className="s_block w-[2px] h-3 bg-gray-300"></span>
                      <span className="s_block w-[2px] h-3 bg-gray-300"></span>
                      {/* <span className="s_block w-[2px] h-3 bg-gray-300"></span> */}
                      <span className="s_block w-[2px] h-3 bg-gray-300 rounded-r-[1px]"></span>
                    </div>
                  </button>

                  {/* PUBLISH & UNBLISH */}
                  <button
                    className={`status-view collapsed `}
                    type="button"
                    id={`status-${event?.id}-${tempEventRecord?.id}`}
                    onClick={async (data: any) => {
                      if (
                        eventRecord?.status !== "yes" &&
                        (eventRecord?.listing_ticket_files?.length > 0 ||
                          eventRecord?.mobile_links?.length > 0) &&
                        Number(eventRecord?.quantity_available) !==
                          Number(
                            eventRecord?.listing_ticket_files?.length ||
                              eventRecord?.mobile_links?.length
                          ) &&
                        (eventRecord?.ticket_type?.id === "mobile-qr" ||
                          eventRecord?.ticket_type?.id === "pdf" ||
                          eventRecord?.ticket_type === "eticket" ||
                          eventRecord?.ticket_type?.id === "mobile-link" ||
                          eventRecord?.ticket_type === "mobile-link")
                      ) {
                        const ifError = await setLocalValidationToEventRecords(
                          eventRecord?.id,
                          true
                        );

                        if (ifError === false) {
                          showAlert(TicketError, true, 3000);
                        }
                      } else {
                        handleListingAction(
                          eventRecord?.status === "yes"
                            ? "unpublish-all"
                            : "publish-all",
                          eventRecord?.id
                        );
                      }
                    }}
                  >
                    {eventRecord?.status === "yes" ? (
                      <>
                        <IKTSVG
                          className="group-hover:hidden flex items-center justify-center fill-green-500 group-hover:fill-indigo-500 transition"
                          path={HaxPublishGray}
                          svgClassName="w-[1rem] h-[1.155rem]"
                        />
                        <IKTSVG
                          className="hoverIcon group-hover:flex hidden items-center justify-center fill-gray-400 group-hover:fill-indigo-500 transition"
                          path={unpublish}
                          svgClassName="w-[1rem] h-[1.155rem]"
                        />
                      </>
                    ) : (
                      <>
                        <IKTSVG
                          className="hoverIcon group-hover:flex hidden items-center justify-center fill-green-500 group-hover:fill-indigo-500 transition"
                          path={HaxPublishGray}
                          svgClassName="w-[1rem] h-[1.155rem]"
                        />
                        <IKTSVG
                          className="group-hover:hidden flex items-center justify-center fill-gray-400 group-hover:fill-indigo-500 transition"
                          path={unpublish}
                          svgClassName="w-[1rem] h-[1.155rem]"
                        />
                      </>
                    )}
                  </button>
                  <button
                    className={`group flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] border-l ${
                      isEventRecordError && "border-t-rose-500"
                    } ${
                      isDateBeforeValidLogDate(eventRecord?.created_at) &&
                      !isGodAdmin
                        ? "pointer-events-none"
                        : ""
                    } `}
                    type="button"
                    disabled={
                      isDateBeforeValidLogDate(eventRecord?.created_at) &&
                      !isGodAdmin
                    }
                    id={`logs-tooltip${event?.id}-${eventRecord?.id}`}
                    onClick={(e: any) => layout.setLogsData(eventRecord)}
                  >
                    <Tooltip
                      anchorId={`logs-tooltip${event?.id}-${eventRecord?.id}`}
                      content={`Log`}
                      place={`top`}
                      variant="light"
                      className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10`}
                    />
                    <IKTSVG
                      className={`flex items-center justify-center ${
                        isDateBeforeValidLogDate(eventRecord?.created_at) &&
                        !isGodAdmin
                          ? "fill-gray-500"
                          : "fill-violet-500"
                      } group-hover:fill-indigo-500 transition`}
                      path={logIcon}
                      svgClassName="w-4 h-4"
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            className={` ${
              isGodAdmin && eventRecord?.isLock
                ? "bg-gray-100 pointer-events-none"
                : "bg-white"
            } sticky md:hidden right-0 p-0   ml-auto min-w-[1.875rem] cursor-pointer h-full z-10`}
            onClick={(e) => {
              editViewPopOpen(eventRecord, event);
            }}
          >
            <IKTSVG
              className={`fill-violet-500 border-l flex items-center justify-center transition bg-inherit h-full`}
              svgClassName="w-3 h-3"
              path={EditSquareSVG}
            />
          </div>
        </div>

        {/* ALL ERRORS, PROCESSING, TICKET HOLD, RESTRICTIONS */}
        <EventRecordStatus
          event={event}
          eventRecord={eventRecord}
          eIndex={eIndex}
          rIndex={rIndex}
        />
      </div>
    </>
  );
};

export default EventFieldViewMobile;
