/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import Cookies from "js-cookie";
import _, { isArray } from "lodash";
import Pusher from "pusher-js";
import { useContext, useEffect } from "react";
import { decimalNumber, resetPPEFields } from "../../../helpers/Functions";
import {
  AUTHDATA,
  CHANNEL_INVENTORY_LISTING_UPDATE_EVENT_ID,
  CHANNEL_LISTING_DELETE,
  CHANNEL_LISTING_PUBLISHED_MARKETPLACE_EVENT_ID,
  CHANNEL_LISTING_REMOVED_MARKETPLACE_EVENT_ID,
  CHANNEL_ON_HOLD_EVENT_ID,
  CHANNEL_RELEASED_EVENT_ID,
} from "../../../helpers/const";
import { InventoryContext } from "./InventoryProvider";

const PusherSetup = ({
  eventListData,
  setListCountingInfo,
  setList,
  // loadMoreListing,
  countRefetch,
}: any) => {
  const layout = useContext(InventoryContext);
  let broadcastId = AUTHDATA?.broadcast_id;

  const handleUpdatingListing = (pusherData: any) => {
    pusherData &&
      pusherData?.is_bulk_delete !== 1 &&
      setTimeout(() => {
        setList((events: any) => {
          let finalResult = events.map((event: any) => {
            if (event.id === pusherData?.event_id) {
              return {
                ...event,
                requireSelectAll: {},
                eventRecords:
                  event.eventRecords &&
                  event.eventRecords.map((record: any) => {
                    if (
                      record.id.toString() === pusherData.list_id ||
                      record.id.toString() === pusherData?.original_list_id
                    ) {
                      //handle markeplace and simple error
                      let errors = isArray(pusherData?.errors)
                        ? pusherData?.errors
                        : [];
                      let errorsArray: Array<any> = [];
                      errors.map((error: any) => {
                        let [first, ...rest] = error.split(":");
                        rest = rest.join(":");
                        errorsArray.push({
                          errors: [rest ? rest : first],
                          exchange: rest ? first : "",
                        });
                      });
                      console.log(record, "")
                      return {
                        ...record,
                        status: pusherData.status,
                        errors: errorsArray,
                        selected: false,
                        id:
                          pusherData?.is_bulk_update !== 1
                            ? pusherData.list_id
                            : record?.id,
                        loader: false,
                        isInputChange: false,
                        replacible: {},
                        bulkLoader: false,
                        ticketFiles: record?.ticketFiles,
                        deleteLoader: false,
                        price_per_exchange: pusherData?.price_per_exchange,
                        ...((pusherData?.price_per_exchange === 0 ||
                          pusherData?.price_per_exchange === false) && {
                          ppePartners: resetPPEFields(
                            record?.["ppePartners"],
                            record?.sell_price,
                            "ppePartners"
                          ),
                          ticket_exchange_prices: resetPPEFields(
                            record?.["ppePartners"],
                            record?.sell_price,
                            "ticket_exchange_prices"
                          ),
                        }),
                        face_value:
                          typeof record.face_value === "object"
                            ? decimalNumber(record.face_value?.value)
                            : decimalNumber(record.face_value),
                        sell_price:
                          typeof record.sell_price === "object"
                            ? decimalNumber(record.sell_price?.value)
                            : decimalNumber(record.sell_price),
                        quantity_available: Number(pusherData?.quantity_available),
                        quantity_sold: Number(pusherData?.quantity_sold)
                      };
                    } else {
                      return record;
                    }
                  }),
              };
            } else {
              return event;
            }
          });

          layout?.setUpdateAllLoader((prev: any) => {
            if (prev === false) {
              finalResult = finalResult?.map((event: any) => ({
                ...event,
                eventRecords:
                  event.eventRecords?.map((record: any) => ({
                    ...record,
                    loader: false,
                    // isInputChange: false,
                    // selected: false,
                  })) || [],
              }));
            }
            return prev;
          });
          return finalResult;

          // fix issue single update for multiple records
          // return finalResult?.map((event: any) => ({
          //   ...event,
          //   eventRecords:
          //     event.eventRecords?.map((record: any) => ({
          //       ...record,
          //       loader: false,
          //       isInputChange: false,
          //       selected: false,
          //     })) || [],
          // }));
          //return finalResult;
        });
      }, 1000);

    setTimeout(() => {
      layout.setisBulkActionLoader(false);
    }, 3000);
  };

  const handleHoldListing = (pusherData: any) => {
    let listCount: any = "";
    pusherData?.is_bulk_delete !== 1 &&
      setList((events: any) => {
        let finalResult = events.map((event: any) => {
          if (event.id === pusherData?.event_id) {
            listCount++;
            return {
              ...event,
              eventRecords:
                event.eventRecords &&
                event.eventRecords.map((record: any) => {
                  if (record.id.toString() === pusherData.list_id) {
                    // NO NEED THIS LOGIC BECAUSE IT IS PROVIDED BY PUSHER DATA
                    // let quantity_available = record?.quantity_available;
                    // Object.values(pusherData?.on_hold_breakdown).map(
                    //   (holdData: any) => {
                    //     quantity_available =
                    //       quantity_available - holdData?.hold_quantity;
                    //   }
                    // );

                    // let totalHold = 0;
                    // Object.keys(pusherData?.on_hold_breakdown).length > 0 &&
                    //   Object.values(pusherData?.on_hold_breakdown)?.map(
                    //     (holdData: any) => {
                    //       totalHold = totalHold + holdData?.hold_quantity;
                    //     }
                    //   );

                    // setListCountingInfo((pre: any) => ({
                    //   ...pre,
                    //   total_hold_listings: listCount,
                    //   total_hold_tickets: totalHold,
                    // }));
                    countRefetch();
                    return {
                      ...record,
                      hold_tickets: Object.values(
                        pusherData?.on_hold_breakdown
                      ),
                      quantity_available: pusherData?.quantity_available,
                    };
                  } else {
                    return record;
                  }
                }),
            };
          } else {
            return event;
          }
        });
        return finalResult;
      });
  };

  const handleReleasedListing = (pusherData: any) => {
    pusherData?.is_bulk_delete !== 1 &&
      setList((prev: any) => {
        return prev.map((event: any) => {
          if (event.id === pusherData?.event_id) {
            return {
              ...event,
              eventRecords:
                event.eventRecords &&
                event.eventRecords.map((record: any) => {
                  if (record.id.toString() === pusherData?.list_id) {
                    // setListCountingInfo((pre: any) => ({
                    //   ...pre,
                    //   total_hold_listings: 0,
                    //   total_hold_tickets: 0,
                    // }));
                    countRefetch();
                    return {
                      ...record,
                      hold_tickets: [],
                      quantity_available: pusherData?.quantity_available,
                    };
                  } else {
                    return record;
                  }
                }),
            };
          } else {
            return event;
          }
        });
      });
  };

  let dataReceivedCount: any = 0;
  let eventIds: any = [];
  let listId: any = [];

  const handleDeleteListing = async (pusherData: any) => {
    dataReceivedCount++;
    eventIds?.push(pusherData?.event_id);
    listId?.push(pusherData?.list_id);

    setList((prev: any) => {
      let final = prev;
      if (final) {
        const filteredEventsTemp = final?.filter((event: any) => {
          return (
            event?.eventRecords?.length === 0 && eventIds?.includes(event?.id)
          );
        });

        // NEXT FETCH DATA AFTER API CALL - OLD CODE
        // if (_.size(filteredEventsTemp) > 0) {
        //   layout.setPusherDeleteLoader(true);
        //   filteredEventsTemp?.map((obj: any) => {
        //     loadMoreListing(obj, "pushercall");
        //   });
        // }

        if (
          _.some(filteredEventsTemp, (event: any) => event?.total_listings > 0)
        ) {
          layout.setPusherDeleteLoader(true);

          filteredEventsTemp
            .filter((event: any) => event?.total_listings > 0)
            .forEach((obj: any) => {
              // loadMoreListing(obj, "pushercall");
            });
        }
        // else {
        // final = final?.filter(
        //   (event: any) =>
        //     event?.total_listings > 0 &&
        //     (event.eventRecords === undefined ||
        //       event.eventRecords.length > 0)
        // );
        // }
      }
      return final;
    });

    // if (allIDs?.length === dataReceivedCount) {
    //   setTimeout(() => {
    //     setList((prev: any) => {
    //       let softDeletedCount = 0;
    //       let final = prev.map((event: any) => {
    //         if (eventIds.includes(event.id)) {
    //           let eventRecords: any;
    //           eventRecords =
    //             event.eventRecords &&
    //             event.eventRecords?.map((record: any) => {
    //               if (listId.includes(record?.id)) {
    //                 softDeletedCount++;
    //                 return {
    //                   ...record,
    //                   // soft_deleted: true,
    //                 };
    //               } else {
    //                 return record;
    //               }
    //             });

    //           eventRecords =
    //             event.eventRecords &&
    //             event.eventRecords.filter(
    //               (record: any) => !listId.includes(record?.id.toString())
    //             );

    //           const statusCounts = eventRecords.reduce((acc: any, obj: any) => {
    //             const status = obj.status;
    //             acc[status] = (acc[status] || 0) + 1;
    //             return acc;
    //           }, {});

    //           const totalYesCount = statusCounts["yes"] || 0;
    //           const totalNoCount = statusCounts["no"] || 0;

    //           return {
    //             ...event,
    //             total_qty_available: pusherData?.quantity_available,
    //             total_listings: event?.total_listings - softDeletedCount,
    //             total_unpublished_listings: totalNoCount,
    //             total_published_listings: totalYesCount,
    //             eventRecords: eventRecords,
    //           };
    //         } else {
    //           return event;
    //         }
    //       });
    //       return final;
    //     });
    //   }, 2000);

    //   setTimeout(() => {
    //     setList((prev: any) => {
    //       // RESET & REMOVE THE DELETE ID FROM LOCALSTORAGE
    //       dataReceivedCount = 0;
    //       // DELETE EVENT IF USER DELETE THE ALL LISTING
    //       let filteredEvents = prev?.filter((event: any) => {
    //         if (eventIds.includes(event?.id)) {
    //           // if (
    //           //   event?.isOpen &&
    //           //   (!event?.eventRecords || event?.eventRecords.length === 0)
    //           // ) {
    //           //   return false;
    //           // }
    //           // if (
    //           //   event?.eventRecords?.length > 0 ||
    //           //   event?.total_listings - event?.totalSelectedCount !== 0
    //           // ) {
    //           //   return true;
    //           // } else {
    //           //   return false;
    //           // }
    //           if (
    //             // event?.total_listings - event?.totalSelectedCount !==
    //             // 0
    //             event?.total_listings !== 0
    //           ) {
    //             return true;
    //           } else {
    //             return false;
    //           }
    //         } else {
    //           return true;
    //         }
    //       });

    //       if (filteredEvents) {
    //         const filteredEventsTemp = filteredEvents?.filter((event: any) => {
    //           return (
    //             event?.eventRecords?.length === 0 &&
    //             eventIds?.includes(event?.id)
    //           );
    //         });

    //         // NEXT FETCH DATA AFTER API CALL
    //         if (_.size(filteredEventsTemp) > 0) {
    //           layout.setPusherDeleteLoader(true);
    //           filteredEventsTemp?.map((obj: any) => {
    //             loadMoreListing(obj, "pushercall");
    //           });
    //           // eventIds = [];
    //           // listId = [];
    //           // sessionStorage.removeItem("checkedIds");
    //         }

    //         // OPEN THE EVNET IF IT IS COLLAPSED
    //         setTimeout(() => {
    //           _.size(filteredEventsTemp) > 0 &&
    //             eventIds?.forEach((item: any) => {
    //               let header: any = document.getElementById(item);
    //               let body: any = document.getElementById(
    //                 `EventCollapseBody${item}`
    //               );
    //               let btn: any = document.getElementById(
    //                 `collapseIcon-${item}`
    //               );
    //               // if (header) {
    //               //   header.setAttribute("aria-expanded", "false");
    //               //   header.setAttribute("data-te-collapse-collapsed", "true");
    //               //   body.classList.add("hidden");
    //               //   body.removeAttribute("data-te-collapse-show");
    //               //   btn.setAttribute("aria-expanded", "false");
    //               //   btn.setAttribute("data-te-collapse-collapsed", "true");
    //               // }
    //             });
    //           eventIds = [];
    //           listId = [];
    //           sessionStorage.removeItem("checkedIds");
    //         }, 50);
    //       }
    //       layout.setConfirmRowDelete(false);
    //       return filteredEvents;
    //     });
    //   }, 2500);
    // }

    // GET SINGLE EVENT COUNT UPDATE AND EVENT DELETE IF USER SELECTED ALL RECORDS
    // getInventoryEventList({
    //   ...eventListData,
    //   inventoryFilterCopy: { event_id: { id: pusherData?.event_id } },
    // }).then((response: any) => {
    //   if (response?.data?.events) {
    //     const updatedEvent = response?.data?.events?.[0];
    //     setList((events: any) => {
    //       let final = events?.map((event: any) => {
    //         if (!updatedEvent && event.id === pusherData?.event_id) {
    //           return null;
    //         } else if (event.id === updatedEvent?.id) {
    //           return {
    //             ...event,
    //             total_qty_available: updatedEvent?.total_qty_available,
    //             total_listings: updatedEvent?.total_listings,
    //             total_published_listings:
    //               updatedEvent?.total_published_listings,
    //           };
    //         } else {
    //           return {
    //             ...event,
    //           };
    //         }
    //       });
    //       return final.filter((event: any) => event !== null);
    //     });
    //   }
    // });

    //UPDATE FILTER COUNT DATA
    // getCountListing({ ...eventListData }).then((reponse: any) => {
    //   if (reponse?.data) {
    //     setListCountingInfo(reponse?.data);
    //   }
    // });
  };

  const handleListingPublished = async (pusherData: any) => {
    // GET SINGLE EVENT COUNT UPDATE AND EVENT DELETE IF USER SELECTED ALL RECORDS
    // getInventoryEventList({
    //   ...eventListData,
    //   inventoryFilterCopy: { event_id: { id: pusherData?.event_id } },
    // }).then((response: any) => {
    //   if (response?.data?.events) {
    //     const updatedEvent = response?.data?.events?.[0];
    //     setList((events: any) => {
    //       let final = events?.map((event: any) => {
    //         if (!updatedEvent && event.id === pusherData?.event_id) {
    //           return null;
    //         } else if (event.id === updatedEvent?.id) {
    //           return {
    //             ...event,
    //             total_qty_available: updatedEvent?.total_qty_available,
    //             total_listings: updatedEvent?.total_listings,
    //             total_published_listings:
    //               updatedEvent?.total_published_listings,
    //           };
    //         } else {
    //           return {
    //             ...event,
    //           };
    //         }
    //       });
    //       return final.filter((event: any) => event !== null);
    //     });
    //   }
    // });
    //UPDATE FILTER COUNT DATA
    // getCountListing({ ...eventListData }).then((reponse: any) => {
    //   if (reponse?.data) {
    //     setListCountingInfo(reponse?.data);
    //   }
    // });
  };

  useEffect(() => {
    let getAuthData = Cookies.get("authData") as string;
    let allAutData = getAuthData ? JSON.parse(getAuthData) : {};
    broadcastId = allAutData?.broadcast_id;

    if (broadcastId) {
      const pusher = new Pusher(
        process.env.REACT_APP_PUSHER_APP_KEY as string,
        {
          cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER as string,
        }
      );

      pusher.connection.bind("error", function (err: any) {
        if (err?.error?.data?.code === 4004) {
          console.count("Over limit!");
        }
      });

      const channel = pusher.subscribe(broadcastId);

      channel.bind(CHANNEL_ON_HOLD_EVENT_ID, function (data: any) {
        handleHoldListing(data?.data);
      });

      channel.bind(CHANNEL_RELEASED_EVENT_ID, function (data: any) {
        handleReleasedListing(data?.data);
      });

      channel.bind(
        CHANNEL_LISTING_PUBLISHED_MARKETPLACE_EVENT_ID,
        function (data: any) {
          handleListingPublished(data?.data);
        }
      );

      channel.bind(
        CHANNEL_LISTING_REMOVED_MARKETPLACE_EVENT_ID,
        function (data: any) {
          // we call publish function becuase both need to be done same thing to update counts.
          handleListingPublished(data?.data);
        }
      );

      channel.bind(
        CHANNEL_INVENTORY_LISTING_UPDATE_EVENT_ID,
        function (data: any) {
          handleUpdatingListing(data?.data);
        }
      );

      channel.bind(CHANNEL_LISTING_DELETE, function (data: any) {
        // if (data?.data) {
        //   setList((prev: any) => {
        //     return prev.map((event: any) => {
        //       if (eventIds.includes(event.id)) {
        //         return {
        //           ...event,
        //           eventRecords: event.eventRecords?.map((record: any) => {
        //             if (
        //               existInSessionstorage("checkedIds")?.includes(record?.id)
        //             ) {
        //               return {
        //                 ...record,
        //                 deleteLoader: true,
        //               };
        //             } else {
        //               return record;
        //             }
        //           }),
        //         };
        //       } else {
        //         return event;
        //       }
        //     });
        //   });
        // }
        // layout.setConfirmRowDelete(true);

        handleDeleteListing(data?.data);
      });

      channel.bind_global(function (event: any, data: any) {
        console.log(event, data);
      });

      return () => {
        pusher.disconnect();
        pusher.unsubscribe(broadcastId);
        channel.unbind();
      };
    }
  }, []);

  return <></>;
};

export default PusherSetup;
