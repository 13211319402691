import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import NoJSXComponent from "../../../../../components/noJSX/NoJSXComponent";
import { handleShift } from "../../../../../helpers/Functions";
import { refreshEventsWhileActionPerform } from "../../../core/_functions";
import InventoryNoJSX from "../../../core/InventoryNoJSX";

type TListingCheckbox = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  eIndex: string | number;
  rIndex: string | number;
  list: any;
  setList: (...args: any[]) => void;
  MemoizedHandleTableField: (...args: any[]) => void;
};

const ListingCheckbox = ({
  event,
  eventRecord,
  eIndex,
  rIndex,
  list,
  setList,
  MemoizedHandleTableField,
}: TListingCheckbox) => {
  const { isGodAdmin } = NoJSXComponent();
  const {
    disabledAll,
    forRowDisabled,
    isEventRecordError,
    isEventRecordBEError,
  } = InventoryNoJSX({
    event,
    eventRecord,
  });

  return (
    <div
      style={{ width: "40px" }}
      className={`flex td_block items-center justify-center 
    ${isGodAdmin && "!left-[2.5rem]"} min-w-[2.5rem] max-w-[2.5rem]
      h-[2.5rem] text-center sticky left-0 no-scroll p-0 checkbox_td z-[3] bg-inherit overflow-hidden`}
      id="checkbox_shad"
    >
      <label
        className={`bg-inherit select-none w-full h-full top-0 left-0 absolute cursor-pointer p-1 border-r group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]
       ${forRowDisabled} ${disabledAll && "pointer-events-none"} ${
          eventRecord?.selected ? "bg-indigo-500 bg-opacity-[7%]" : ""
        }  ${
          ((eventRecord?.errors &&
            Object.keys(eventRecord?.errors ? eventRecord?.errors : []).length >
              0) ||
            eventRecord?.status === "failed") &&
          "row-error-new bg-opacity-[5%] !group-hover:bg-rose-550 group-hover:bg-opacity-[5%]"
        } ${
          (isEventRecordError ||
            eventRecord?.status === "failed" ||
            isEventRecordBEError) &&
          "row-error-new !group-hover:bg-rose-550"
        }`}
        htmlFor={`checkbox-all-${eIndex}${rIndex}`}
      >
        {eventRecord?.cancelled_order_id && (
          <span
            data-tooltip-id={`recreated-listings-${eIndex}${rIndex}`}
            className="triangle-up cursor-pointer block"
          >
            <TooltipPortal
              id={`recreated-listings-${eIndex}${rIndex}`}
              content={`Listing re-created due to cancelled order ${eventRecord?.cancelled_order_id}`}
              place="top"
              variant="light"
              className="text-center !bg-white !opacity-100 shadow !px-1 !py-1 font-medium text-xs z-100 max-w-[6rem] mt-1.5 "
              positionStrategy="fixed"
              clickable={true}
            />
          </span>
        )}
      </label>
      <input
        name="selected"
        id={`checkbox-all-${eIndex}${rIndex}`}
        type="checkbox"
        checked={eventRecord?.selected ? true : false}
        className={`${forRowDisabled} ${
          disabledAll && "pointer-events-none "
        } ${
          eventRecord?.hold_tickets?.length && "pointer-events-none "
        }  w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative`}
        onChange={(event: any) =>
          MemoizedHandleTableField(event, eIndex, rIndex)
        }
        onClick={(e) => {
          handleShift(e, rIndex, event?.eventRecords);
          setTimeout(() => {
            setList(refreshEventsWhileActionPerform(list));
          }, 70);
        }}
        disabled={eventRecord?.hold_tickets?.length > 0}
      />
    </div>
  );
};

export default ListingCheckbox;
