import { useContext, useEffect, useRef } from "react";
import AngleLeft from "../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { SalesContext } from "../core/SalesProvider";
import SalesCountBoxes from "./SalesCountBoxes";

const TabBody = ({
  children,
  bodyId,
  data,
  countBoxTitle,
  shimmerLoader,
  salesOptions,
}: any) => {
  const layout = useContext(SalesContext);

  const filter_scroll: any = useRef();
  const scroll_filter = (scrollOffset: any) => {
    filter_scroll.current.scrollLeft += scrollOffset;
  };
  useEffect(() => {
    function hideArrowScroll() {
      const left_sc: any = document.getElementById("scroll_left");
      const right_sc: any = document.getElementById("scroll_right");
      const filter_scroll: any = document.getElementById("filter_scroll");
      const maxScrollLeft: any =
        filter_scroll?.scrollWidth - filter_scroll?.clientWidth;

      if (filter_scroll?.scrollLeft === 0) {
        left_sc.style.display = "none";
      } else {
        left_sc.style.display = "flex";
      }
      if (Math.round(filter_scroll?.scrollLeft) >= maxScrollLeft) {
        right_sc.style.display = "none";
      } else {
        right_sc.style.display = "flex";
      }
    }
    const filter_scroll = document.getElementById("filter_scroll"); // Make sure filter_scroll is defined and references the correct element
    filter_scroll?.addEventListener("scroll", hideArrowScroll);
    hideArrowScroll();
    return () => {
      filter_scroll?.removeEventListener("scroll", hideArrowScroll); // Cleanup: Remove event listener when component unmounts
    };
  }, []);
  return (
    <div
      className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear ${
        layout.tabValue === bodyId ? "block" : "hidden"
      }`}
      id={bodyId}
      role="tabpanel"
      aria-labelledby={`tabs-${bodyId}`}
      data-te-tab-active
    >
      {/*first layer*/}
      <div className="relative px-5 pt-3">
        <div
          id={`scroll_right`}
          className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center right-0 bg-gray-100 rounded-l group hover:bg-indigo-500 cursor-pointer"
          // onClick={() => scrollLeft()}
          onClick={() => scroll_filter(50)}
        >
          <IKTSVG
            path={AngleRight}
            svgClassName="w-1.5 h-[.5625rem]"
            className=" group-hover:fill-white"
          />
        </div>
        <div
          id={`scroll_left`}
          className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center left-0 bg-gray-100 rounded-r group hover:bg-indigo-500 cursor-pointer"
          onClick={() => scroll_filter(-50)}
          // onClick={() => scroll_filter(50)}
        >
          <IKTSVG
            path={AngleLeft}
            svgClassName="w-1.5 h-[.5625rem]"
            className=" group-hover:fill-white"
          />
        </div>
        <div className="divide-gray-200 divide-y-2">
          <div
            ref={filter_scroll}
            id={`filter_scroll`}
            className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
          >
            <SalesCountBoxes
              name={`${countBoxTitle}`}
              isCheckbox={false}
              countValue={data?.revenue?.toLocaleString()}
              ticketCount={undefined}
              extraProp={true}
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
            <SalesCountBoxes
              name="Orders"
              isCheckbox={false}
              countValue={data?.orders_count}
              ticketCount={data?.tickets_total_counts}
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
            <SalesCountBoxes
              name="E-ticket"
              isCheckbox={data?.eticket > 0 ? true : false}
              countValue={data?.eticket}
              ticketCount={data?.eticket_tickets}
              postKey="pdf"
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
            <SalesCountBoxes
              name="External Transfer"
              isCheckbox={data?.mobile > 0 ? true : false}
              countValue={data?.mobile}
              ticketCount={data?.mobile_tickets}
              postKey="mobile"
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
            {/* <SalesCountBoxes
              name="Memeber/Season card"
              isCheckbox={true}
              countValue={0}
              ticketCount={0}
              postKey="mobile"
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            /> */}

            <SalesCountBoxes
              name="Mobile Link/PKPASS"
              isCheckbox={data?.mobile_link > 0 ? true : false}
              countValue={data?.mobile_link}
              ticketCount={data?.mobile_link_tickets}
              postKey="mobile-link"
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
            <SalesCountBoxes
              name="Mobile Screenshot"
              isCheckbox={data?.mobile_screenshot > 0 ? true : false}
              countValue={data?.mobile_screenshot}
              ticketCount={data?.mobile_screenshot_tickets}
              postKey="mobile-qr"
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
            <SalesCountBoxes
              name="Paper"
              isCheckbox={data?.paper > 0 ? true : false}
              countValue={data?.paper}
              ticketCount={data?.paper_tickets}
              postKey="paper"
              shimmerLoader={shimmerLoader}
              salesOptions={salesOptions}
            />
          </div>
        </div>
      </div>

      {children}
    </div>
  );
};

export default TabBody;
